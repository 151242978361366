import {ButtonHTMLAttributes} from "react";
import styles from './FancyButton.module.scss';

type FancyButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    color?: string;
}
export const FancyButton = ({className, color, ...props}: FancyButtonProps) => <button
    {...props}
    className={`${className}
     ${styles.btn}
      ${styles[`btn-${color}`]}`}
/>