import {forwardModal} from "./Modal/SideModal";

export const About = forwardModal('about', () =>
  <div style={{width: '100%'}}>
    <div className='w-100' style={{whiteSpace: 'pre', fontFamily: 'var(--bs-font-monospace)', overflow: 'hidden'}}>
      Задумано для работы в полноэкранном режиме (нажми <b>F11</b>).{'\n'}
      Любые изменения настроек сохраняются, так что если долго настраивал{'\n'}
      таблицу ставок а потом закрыл страницу, по возвращении всё восстановится.{'\n'}
      Если что-то сломалось по ходу дела - <s>мне похуй</s> попробуй перезагрузить страницу.{'\n'}
      Если перезагрузка не помогла - значит не повезло. Бывает.{'\n'}
      Так же думал присандалить сюда плейер YouTube, но он не работает во фрейме (╯°□°）╯︵ ┻━┻{'\n'}
      {'\n'}
      {'\n'}
      Старт/Стоп - Двойной клик на таймере{'\n'}
      Настройки:{'\n'}
      Меняй таблицу, чтобы настроить ставки{'\n'}
      Нажми "-" чтобы удалить сточку в таблице{'\n'}
      Нажми "+" чтобы внизу таблицы добавить сточку{'\n'}
      Зажми 3 полоски возле ячейки с уровнем в таблице и перетяни на нужное место если необходимо{'\n'}
      "►" в таблице установит текущий уровень{'\n'}
    </div>
    <br/>
    Разработчик Богдан Рудаков <a href="mailto:bogdan@desire-s.ru">bogdan@desire-s.ru</a>
    <br/>
    Ищешь работу разраба?
    <br/>
    (～￣▽￣)～ пиши <a href="mailto:hi@desire-s.ru">hi@desire-s.ru</a>
  </div>)
