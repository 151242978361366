import {
    createContext,
    PropsWithChildren,
    PropsWithoutRef,
    TransitionEventHandler,
    useContext,
    useEffect,
    useMemo, useRef,
    useState
} from "react";
import styles from './GameCard.module.scss'
import {className} from "../utils";

export type CardValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'j' | 'q' | 'k';
export type CardType = 's' | 'd' | 'c' | 'h';
export type CardName = `${CardType}${CardValue}`;

export const knownCardValues: readonly CardValue[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'j', 'q', 'k', 1];
export const knownCardTypes: readonly CardType[] = ['s', 'd', 'c', 'h'];

export interface GameCardProps extends PropsWithoutRef<any> {
    show: boolean;
    value: CardName;
}

const valueSymbolMap: Record<CardValue, string> = {
    1: 'A',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    j: 'J',
    q: 'Q',
    k: 'K',
};
const typeSymbolMap: Record<CardType, string> = {
    s: '♠',
    d: '♦',
    c: '♣',
    h: '♥',
};
const typeColorMap: Record<CardType, string> = {
    s: '#000',
    d: '#F00',
    c: '#000',
    h: '#F00',
};

const ChainContext = createContext({push: (v: any) => {}});
export const GameCardAnimationChain = ({children}: PropsWithChildren) => {
    const chain = useRef<Function[]>([]);
    const push = useMemo(() => (fn: any) => chain.current = [...chain.current, fn], []);

    useEffect(() => {
        const tmr = setInterval(() => {
            const [fn = () => {}, ...rest] = chain.current;
            fn();
            chain.current = rest;
        }, 100);

        return () => clearInterval(tmr);
    }, []);

    return <ChainContext.Provider value={{push}}>
        <div>
            {children}
        </div>
    </ChainContext.Provider>
}

const Bump = ({children}: PropsWithChildren) => <div
    className={`position-relative ${styles.bevel}`}
    title={children as any}
>
    {children}
</div>

const CardFace = ({value}: { value: GameCardProps['value'] }) => {
    const t: CardType = value[0] as any;
    const v: CardValue = value.slice(1, value.length) as any;
    const symbol = useMemo(() => typeSymbolMap[t], [t]);
    const color = useMemo(() => typeColorMap[t], [t]);
    const _value = useMemo(() => valueSymbolMap[v], [v]);

    return <div className={`${styles.modern} ${styles.CardFace}`} style={{'--color': color} as any}>
        <div className={styles.a1}>
            {_value}
        </div>
        <div className={styles.a2}>
            <Bump>{symbol}</Bump>
        </div>
    </div>
}

export const PlainGameCard = ({show, value}: GameCardProps) =>
    <div className={className({
        [styles.show]: show,
    }, styles.Card)}>
        <CardFace value={value}/>
        <div className={styles.CardBackFace}/>
    </div>

export const GameCard = (props: GameCardProps) => {
    const [show, setShow] = useState(props.show);
    const [value, setValue] = useState(props.value);
    const [state, setState] = useState<'closing' | 'opening' | 'static'>('static');
    const chain = useContext(ChainContext);

    useEffect(() => {
        if (value === props.value) {
            return;
        }
        chain.push(() => {
            setState('closing');
            setShow(false);
        })
    }, [value, props.value]);

    const handleTransitionEnd = () => {

        switch (state) {
            case 'closing':
                setState('opening');
                setShow(true)
                setValue(props.value);
                break;
            case 'opening':
                setState('static');
                break;
        }
    };
    return <div onTransitionEnd={handleTransitionEnd} style={{perspective: 500}}>
        <PlainGameCard show={show} value={value}/>
    </div>
}
