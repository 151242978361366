import {selectDuration, setDuration as _setDuration} from "../store/counterSlice";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

export const Duration = () => {
  const dispatch = useDispatch();
  const duration = useSelector(selectDuration);
  // console.log(duration);

  const values = {
    min: duration,
  }
  const {control, ...form} = useForm({
    defaultValues: values,
    values: values,
  });
  const {min} = form.watch();


  useEffect(() => {
    dispatch(_setDuration(min));
  }, [min]);

  return <label>
    <b>Таймер</b>

    <span className="d-flex align-baseline">
      <input type="number" {...form.register('min')} min={1} max={60}/>
      <b>мин</b>
    </span>
  </label>
  /*return <table>
    <thead>
    <tr>
      <td>&nbsp;-&nbsp;</td>
      <td></td>
      <th>мин</th>
    </tr>
    </thead>
    <tbody>
    <tr className='active'></tr>
    <tr>
      <td>&nbsp;-&nbsp;</td>
      <th>Таймер</th>
      <td>
        <input type="number" {...form.register('min')} min={1} max={60}/>
      </td>
    </tr>
    </tbody>
  </table>*/
}
