import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counterSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer
  },
  middleware: [
    store => next => action => {
      const res = next(action)

      localStorage.state = JSON.stringify(store.getState());

      return res;
    }
  ],
  preloadedState: JSON.parse(localStorage.state || '{}'),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
