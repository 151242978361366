import React, {ButtonHTMLAttributes} from "react";
import styles from './Button.module.scss';
import {className} from "../../utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: any,
    appearance: 'primary' | 'secondary' | 'icon' | 'text',
    color?: 'red' | 'green' | 'blue' | 'gray' | 'yellow'
}

const Button = ({
                    children,
                    className: klass,
                    icon,
                    appearance,
                    color = 'blue',
                    ...props
                }: ButtonProps) =>
    <button
        {...props}
        className={`${styles.button} ${styles[appearance]} ${styles[color]} ${klass}`}
    >
        <span className='position-relative'>
            {icon}
            <span className='t1'>{children}</span>
        </span>
    </button>

export const TextButton = (props: Omit<ButtonProps, 'appearance'>) => <Button {...props} appearance='text' color='gray'/>
export const PrimaryButton = (props: Omit<ButtonProps, 'appearance'>) => <Button {...props} appearance='primary'/>
export const SecondaryButton = (props: Omit<ButtonProps, 'appearance'>) => <Button {...props} appearance='secondary'/>
export const IconButton = (props: Omit<ButtonProps, 'appearance' | 'children'>) => <Button {...props}
                                                                                           appearance='secondary'/>
