import {CardName, GameCard, GameCardAnimationChain, knownCardTypes, knownCardValues} from "./GameCard";
import React, {useEffect, useState} from "react";
import {shuffle} from "../utils";
import styles from './Combinations.module.scss';

type CardsCombination = []
    | [CardName]
    | [CardName, CardName]
    | [CardName, CardName, CardName]
    | [CardName, CardName, CardName, CardName]
    | [CardName, CardName, CardName, CardName, CardName];


interface RenderCardProps {
    card: CardName | undefined,
}

const refreshInterval = 60 * 10;
const refreshIntervalRandomness = 0.5;
const randomRefreshInterval = () => {
    let d1 = refreshInterval / 2;
    let d2 = Math.random() * refreshIntervalRandomness;

    return refreshInterval - d1 + refreshInterval * d2;
}
const RenderCard = ({card}: RenderCardProps) => <GameCard show={!!card}
                                                          value={card || 's6'}/>

interface CombinationProps {
    name: string;
    description: any;
    cardsGenerator: () => CardsCombination
}

const Combination = ({name, description, cardsGenerator}: CombinationProps) => {
    const [cards, setCards] = useState<CardsCombination>(cardsGenerator());

    useEffect(() => {
        const tmr = setInterval(() => setCards(cardsGenerator()), 1000 * randomRefreshInterval());
        return () => clearInterval(tmr);
    }, [cardsGenerator]);

    return <tr>
        <td align='right'>
            <p className={styles.combName}><strong>{name}</strong></p>
            <p className={styles.combDescription}>{description}</p>
        </td>
        <td>
            <div className="d-flex gap-2 p-1">
                {[0, 0, 0, 0, 0].map((_, i) => <RenderCard key={i} card={cards[i]}/>)}
            </div>
        </td>
    </tr>;
}


export const Combinations = () => <GameCardAnimationChain>
    <table>
        <tbody>
        <Combination
            name='Старшая карта'
            description=''
            cardsGenerator={() => {
                const [v] = shuffle(knownCardValues);
                const [t] = shuffle(knownCardTypes);
                return [`${t}${v}`] as any;
            }}
        />
        <Combination
            name='Пара'
            description={<span>две карты <br/> одного достоинства</span>}
            cardsGenerator={() => {
                const [v] = shuffle(knownCardValues);
                const [t1, t2] = shuffle(knownCardTypes);
                return [`${t1}${v}`, `${t2}${v}`] as any;
            }}
        /><Combination
            name='Две пары'
            description='ну... две пары, хули'
            cardsGenerator={() => {
                const [v1, v2] = shuffle(knownCardValues);
                const [t11, t12] = shuffle(knownCardTypes);
                const [t21, t22] = shuffle(knownCardTypes);
                return [`${t11}${v1}`, `${t12}${v1}`, `${t21}${v2}`, `${t22}${v2}`] as any;
            }}
        />
        <Combination
            name='Сет (тройка)'
            description={<span>три карты <br/> одного достоинства</span>}
            cardsGenerator={() => {
                const [v] = shuffle(knownCardValues);
                const [t1, t2, t3] = shuffle(knownCardTypes);
                return [`${t1}${v}`, `${t2}${v}`, `${t3}${v}`] as any;
            }}
        />
        <Combination
            name='Стрит'
            description={<span>последовательность <br/> из пяти карт</span>}
            cardsGenerator={() => {
                const start = Math.random() * (knownCardValues.length - 5);
                return [...knownCardValues]
                    .slice(start, start + 5)
                    .map(v => `${shuffle(knownCardTypes)[0]}${v}`) as CardsCombination
            }}
        />
        <Combination
            name='Флеш'
            description={<span>пять карт <br/> одной масти</span>}
            cardsGenerator={() => {
                const [t] = shuffle(knownCardTypes);
                return shuffle(knownCardValues)
                    .slice(0, 5)
                    .map(v => `${t}${v}`) as CardsCombination
            }}
        />
        <Combination
            name='Фулл Хаус'
            description={<span>одна пара <br/> и один сет (тройка)</span>}
            cardsGenerator={() => {
                const [v1, v2] = shuffle(knownCardValues);
                const [t11, t12] = shuffle(knownCardTypes);
                const [t21, t22, t23] = shuffle(knownCardTypes);

                return shuffle([
                    `${t11}${v1}`,
                    `${t12}${v1}`,
                    `${t21}${v2}`,
                    `${t22}${v2}`,
                    `${t23}${v2}`,
                ]) as CardsCombination;
            }}
        />
        <Combination
            name='Каре'
            description={<span>четыре карты <br/> одного достоинства</span>}
            cardsGenerator={() => {
                const [v1] = shuffle(knownCardValues);
                return shuffle(knownCardTypes)
                    .map(t => `${t}${v1}`) as CardsCombination;
            }}
        />
        <Combination
            name='Стрит Флеш'
            description={<span>стрит из пяти <br/> карт одной масти</span>}
            cardsGenerator={() => {
                const start = Math.random() * (knownCardValues.length - 5);
                const [t] = shuffle(knownCardTypes);
                return [...knownCardValues]
                    .slice(start, start + 5)
                    .map(v => `${t}${v}`) as CardsCombination
            }}
        />
        <Combination
            name='Флеш Рояль'
            description={<span>стрит флеш <br/> от десяти до туза</span>}
            cardsGenerator={() => {
                const [t] = shuffle(knownCardTypes);
                return [...knownCardValues]
                    .slice(knownCardValues.length - 5, knownCardValues.length)
                    .map(v => `${t}${v}`) as CardsCombination
            }}
        />
        </tbody>
    </table>
</GameCardAnimationChain>