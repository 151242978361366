import React, {useState} from 'react';
import './App.scss';
import {BlindsGrid} from "./BlindsGrid";
import {Timer} from "./Timer";
import {Settings} from "./Settings";
import {About} from "./About";
import {Combinations} from "./Combinations";
import {AudioEffects} from "./AudioEffects";
import {FancyButton} from "./Fields/FancyButton";


const App = () => {
  const [started, setStarted] = useState(false);
  return <>
    {!started && <div className='w-100 min-vh-100 d-flex align-items-center justify-content-center'>
      <FancyButton color='dark' onClick={() => setStarted(true)}/>
    </div>}
    {started && <div className='app-root d-flex align-items-center justify-content-around'>
      {/*<AudioEffects/>*/}
      <BlindsGrid/>
      <About/>

      <Combinations/>

      <div className='d-flex flex-column'>
        <Timer/>
      </div>

    </div>}
  </>;
}

export default App;
