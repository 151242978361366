import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./index";


export const defaultTable = [

  {sb: 5, bb: 10},         // 0:00
  {sb: 10, bb: 25},        // 0:15
  {sb: 25, bb: 50},        // 0:30
  {sb: 50, bb: 100},       // 0:45
  {sb: 100, bb: 200},      // 1:00
  {sb: 200, bb: 300},      // 1:15
  {sb: 300, bb: 500},      // 1:30
  {sb: 500, bb: 700},      // 1:45
  {sb: 700, bb: 1_000},    // 2:00
  {sb: 1_000, bb: 1_500},  // 2:15
  {sb: 1_500, bb: 2_000},  // 2:30
  {sb: 2_000, bb: 2_500},  // 2:45
  {sb: 2_500, bb: 5_000},  // 3:00
  {sb: 5_000, bb: 6_000},  // 3:15
  {sb: 6_000, bb: 7_000},  // 3:30
  {sb: 7_000, bb: 10_000}, // 3:45
  {sb: 1_0000, bb: 15_000},// 4:00
].map(({sb, bb}) => ({sb, bb: bb || sb * 2}));


export interface CounterState {
  level: number,
  table: { sb: number, bb: number }[],
  state: boolean,
  duration: number,
  resetAt: number,
}

export const initialState: CounterState = {
  level: 0,
  table: defaultTable,
  state: false,
  duration: 15,
  resetAt: Date.now(),
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setLevel: (state, action: PayloadAction<number>) => {
      let {payload: level} = action;

      if (level < 0) {
        level = 0;
      }

      if (level >= state.table.length) {
        level = state.table.length - 1
      }

      state.level = level;
    },
    setTable: (state, action: PayloadAction<CounterState['table']>) => {
      let {payload: table} = action;

      if (table.length > 1) {
        state.table = JSON.parse(JSON.stringify(table));
      }
      if (state.level >= state.table.length) {
        state.level = state.table.length - 1;
      }
    },
    play: (state) => {
      state.state = true;
    },
    pause: (state) => {
      state.state = true;
    },
    toggle: (state) => {
      state.state = !state.state;
    },
    reset: (state) => {
      return {...initialState, resetAt: Date.now()};
    },
    setDuration: (state, action: PayloadAction<CounterState['duration']>) => {
      state.duration = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const {play, pause, setTable, setLevel, toggle, reset, setDuration} = counterSlice.actions
export const selectTable = (state: RootState) => state.counter.table
export const selectLevel = (state: RootState) => state.counter.level
export const selectState = (state: RootState) => state.counter.state
export const selectNextBlind = (state: RootState) => state.counter.table[state.counter.level + 1] || {sb: 0, bb: 0}
export const selectCurrentBlind = (state: RootState) => state.counter.table[state.counter.level] || {sb: 0, bb: 0}
export const selectDuration = (state: RootState) => state.counter.duration
export const selectResetAt = (state: RootState) => state.counter.resetAt

export default counterSlice.reducer
