export const stack = (...fns: Function[]) => (...args: any[]) => fns
  .forEach(fn => fn.apply(null, args))

export const className = (classes: Record<string, boolean> = {}, rest = '') => Object
  .entries(classes)
  .filter(([k, v]) => !!v)
  .map(([k]) => k)
  .join(' ') + ' ' + rest;

export const extractPaginated = <T extends Object, >(value: T[] | IPaginated<T>): T[] => {
  if (value?.hasOwnProperty('data')) {
    return (value as any).data as T[];
  }

  return value as any as T[];
}

export const shuffle = <T extends any>(a: readonly T[]) =>  {
  const array = [...a];
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array as T[];
}

export const shuffleParallel = (arrays: any[][]) => {
  const res = arrays.map(a => [...a]);

  // let ci =
}
