import styles from './Table.module.scss';
import {className, extractPaginated, stack} from "../../utils";
import React, {HTMLProps, SyntheticEvent, useEffect, useState} from "react";

// import {TablePagination} from "./TablePagination";

export interface TableConstructorColConfig<R = any, V = any> {
  title?: any;
  align?: 'left' | 'center' | 'right';
  render?: (v: { row: R, value: V, index: number }) => any;
  className?: string;
  width?: number;
}

export interface TableConstructorProps<V, C extends readonly string[]> {
  data: IPossiblePaginated<V>;
  order: C,
  allowReorder: boolean
  actions?: TableConstructorColConfig<V, never>,
  onSwap?: (i1: number, i2: number) => any
  getRowKey?: (row: V, index: number) => string | number
  getRowClass?: (row: V, index: number) => string
  columns: {
    [k in C[number]]: TableConstructorColConfig<V, k extends keyof V ? V[k] : never>
  };
}

const CellGrab = (props: HTMLProps<HTMLTableCellElement>) => <td {...props} style={{...props.style, cursor: 'grab'}}>
  <div className={styles.grabIcon}></div>
</td>

export const TableConstructor = <V extends {}, O extends readonly string[]
>({
    data,
    columns,
    order,
    allowReorder,
    actions = {},
    onSwap = (i1, i2) => {
    },
    getRowKey = (_, index) => index,
    getRowClass = (_, __) => '',
  }: TableConstructorProps<V, O>) => {

  const [_data, set_data] = useState(data);

  useEffect(() => set_data(data), [data]);

  if (actions) {
    actions.className = actions.className || 'text-gray-300';
  }


  const preventSelection = () => document.body.style.userSelect = 'none';
  const unPreventSelection = () => document.body.style.userSelect = 'auto';


  const [grabbedEl, setGrabbedEl] = useState<HTMLTableRowElement | null>(null);
  const [grabbed, setGrabbed] = useState(-1);
  const [hovered, setHovered] = useState(-1);
  const [deltaY, setDeltaY] = useState(0);
  const handleMouseDown = (index: number) => stack(
    (e: any) => {
      console.log(e.target);
      setGrabbed(index);
      setGrabbedEl(e.target);
    },
    preventSelection,
  );
  const handleMouseUp = (index: number) => stack(
    (e: SyntheticEvent<MouseEvent>) => {
      setHovered(-1)
      setGrabbedEl(null)
      if (grabbed === -1) {
        return;
      }
      e.stopPropagation();
      setGrabbed(-1);
      setDeltaY(0);


      onSwap(grabbed, hovered);
    },
    unPreventSelection,
  );

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (grabbed === -1) {
        return;
      }
      setDeltaY(deltaY => e.movementY + deltaY);
    };

    const handleMouseUp = () => {
      if (grabbed === -1) {
        return;
      }
      setTimeout(() => setGrabbed(-1), 300);
      setDeltaY(0);
      unPreventSelection();
      setHovered(-1)
      setGrabbedEl(null);
    }

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
  }, [grabbed])
  const handleMouseEnter = (i: number) => () => setHovered(i);

  const shiftFrom = Math.min(grabbed, hovered);
  const shiftTo = Math.max(grabbed, hovered);
  const shiftSign = Math.sign(grabbed - hovered);


  return <div className='d-flex flex-column position position-relative'>

    {/**
     * при обработке событий перетягивания строк, событие начала перетягивания детектит основная таблица,
     * а события перемещения строки и завершения перетягивания детектит скрытая таблица
     * это нужно для того чтобы корректно отрабатывала анимация перемещения строк
     */}
    <table className={`${styles.table} ${styles.tableShadow}`}>
      <thead>
      <tr>
        {allowReorder && <td></td>}
        {[...order, 'actions']
          // @ts-ignore
          .map(key => ([key, columns[key] ?? actions]))
          .map(([key, {
            align = 'left',
            title = '',
            width
          }], i) => <td className='t4' key={i} align={align} style={{width}}>{title}</td>)}
      </tr>
      </thead>
      <tbody>
      {extractPaginated(_data)
        .map((row, i) => <tr
          className={getRowClass(row, i)}
          key={getRowKey(row, i)}
          onMouseUp={handleMouseUp(i)}
          onMouseEnter={handleMouseEnter(i)}
        >
          <CellGrab/>
          {[...order, 'actions']
            // @ts-ignore
            .map(key => ([key, columns[key] ?? actions]))
            .map(([key, {
              align = 'left',
              // @ts-ignore
              render = ({value}) => value?.toString(),
              className = 't1'
            }], j) => <td
              className={className}
              key={j}
              align={align}
            >
              {/*@ts-ignore*/}
              {render({row, value: row[key], index: i})}
            </td>)}
        </tr>)}
      </tbody>
    </table>


    <table className={`${styles.table} w-100`} style={{pointerEvents: grabbed >= 0 ? 'none' : 'all'}}>
      <thead>
      <tr>
        {allowReorder && <td></td>}
        {[...order, 'actions']
          // @ts-ignore
          .map(key => ([key, columns[key] ?? actions]))
          .map(([key, {
            align = 'left',
            title = '',
            width
          }], i) => <td className='t4' key={i} align={align} style={{width}}>{title}</td>)}
      </tr>
      </thead>
      <tbody>
      {extractPaginated(_data)
        .map((row, i) => <tr
          className={className({
            [styles.grabbed]: grabbed === i,
          }, getRowClass(row, i))}
          key={getRowKey(row, i)}
          style={{
            // @ts-ignore
            '--delta-y': grabbed === i ? deltaY : (shiftFrom <= i && shiftTo >= i) ? grabbedEl?.clientHeight * shiftSign : 0,
          }}
        >
          {allowReorder && <CellGrab onMouseDown={handleMouseDown(i)}/>}
          {[...order, 'actions']
            // @ts-ignore
            .map(key => ([key, columns[key] ?? actions]))
            .map(([key, {
              align = 'left',
              // @ts-ignore
              render = ({value}) => value?.toString(),
              className = 't1'
            }], j) => <td
              className={className}
              key={j}
              align={align}
            >
              {/*@ts-ignore*/}
              {render({row, value: row[key], index: i})}
            </td>)}
        </tr>)}
      </tbody>
    </table>
  </div>
}
